<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      @update:show="limpiarDatos()"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ActualizaSucursal"
    >
      <CustomTabs :active-tab="0" class="text-table">
        <CustomTab :title="$t('label.basicData')">
          <CRow class="mt-2">
            <CCol sm="12" lg="12">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-2 mb-0 required">{{$t('label.company')}}</label>
                  <div class="col-sm-12 col-lg-9 ">
                    <v-select
                      :filter="fuseSearchEmpresa"
                      :options="empresaOptions"
                      :placeholder="$t('label.select')+$t('label.client')"
                      style="width: 100%;"
                      :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-10'}"
                      label="CompanyName"
                      v-model.trim="$v.CompanyId.$model"
                      :is-valid="hasError($v.CompanyId)"
                      :invalid-feedback="$t('label.required')"
                      :class="isEmpresaValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"                
                    />  
                  </div>
                </div>
            </CCol>
            <CCol sm="12" lg="12" class="mt-2">             
              <CInput          
                :label="$t('label.branch')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-2',input:'col-sm-12 col-lg-9'}"
                v-model.trim="$v.BranchName.$model"
                v-uppercase
                :invalid-feedback="errorMessage($v.BranchName)"
                maxlength="500"
                required
                :is-valid="hasError($v.BranchName)"
                size="sm"
              />
            </CCol>
            <CCol sm="12" v-if="actualizar" >              
              <CSelect
                :label="$t('label.status')"
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-2',input:'col-sm-12 col-lg-9'}"
                v-model="Status"
                :value.sync="Status"
                :is-valid="statusSelectColor"
                :options="selectOptions"
                required
                size="sm"
              />
            </CCol>
          </CRow>
        </CustomTab>
        <CustomTab :title="$t('label.location')">
          <CRow class="mt-2">
            <CCol sm="12" lg="6">             
              <CInput
                :label="$t('label.latitude')"
                v-uppercase                
                addLabelClasses="required text-right"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                v-model.trim="$v.Latitude.$model"
                :is-valid="hasError($v.Latitude)"
                :invalid-feedback="errorMessage($v.Latitude)"
                :placeholder="placeholderPos.lat"
                maxlength="100"
                readonly
                size="sm"
              />
            </CCol>
            <CCol sm="12" lg="6">              
              <CInput
                :label="$t('label.longitude')"
                addLabelClasses="required text-right"
                v-uppercase
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                v-model.trim="$v.Longitude.$model"
                :is-valid="hasError($v.Longitude)"
                :invalid-feedback="errorMessage($v.Longitude)"
                :placeholder="placeholderPos.lng"
                maxlength="100"
                readonly
                size="sm"
              />
            </CCol>            
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{$t('label.country')}}</label>
                  <div class="col-sm-12 col-lg-8 ">
                    <v-select
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-9'}" 
                      :filter="fuseSearchPais"
                      :options="paisOptions"
                      v-model="pais"
                      label="CountryName"
                      :placeholder="$t('label.select')+$t('label.country')"
                      :class="isPaisValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{$t('label.state')}}</label>
                  <div class="col-sm-12 col-lg-8 ">
                    <v-select
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
                      :filter="fuseSearchEstado"
                      :options="estadoOptions"
                      ref="vEstado"
                      label="StateName"
                      v-model="estado"
                      :placeholder="$t('label.select')+$t('label.state')"
                      :disabled="isEstadosEmpty"
                      :class="isEstadoValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{$t('label.municipality')}}</label>
                  <div class="col-sm-12 col-lg-8 ">
                    <v-select
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :filter="fuseSearchMunicipios"
                      :options="municipioOptions"
                      label="MunicipalityName"
                      v-model="municipio"
                      :placeholder="$t('label.select')+$t('label.municipality')"
                      :disabled="isMunicipiosEmpty"
                      :class="isMunicipioValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{$t('label.parish')}}</label>
                  <div class="col-sm-12 col-lg-8 ">
                    <v-select
                      :filter="fuseSearchParroquias"
                      :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
                      :options="parroquiaOptions"
                      label="ParishName"
                      addLabelClasses="required"
                      v-model="parroquia"
                      :placeholder="$t('label.select')+$t('label.parish')"
                      :disabled="isParroquiasEmpty"
                      :class="isParroquiaValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" lg="6">
              <div class="form-group form-row">
                <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{$t('label.associatedPort')}}</label>
                  <div class="col-sm-12 col-lg-8 ">
                    <v-select
                      :filter="fuseSearchPuerto"
                      :horizontal="{label:'col-sm-12 col-lg-3',input:'col-sm-12 col-lg-9'}"
                      :options="puertoOptions"
                      label="PortName"
                      :placeholder="$t('label.select')+$t('label.port')"
                      v-model.trim="$v.PortId.$model"
                      :is-valid="hasError($v.PortId)"
                      :invalid-feedback="$t('label.required')"
                      :disabled="isPuertoEmpty"
                      :class="isPuertoValid"
                      :clearable="false"
                      id="v-select-small"
                      size="sm"
                    />             
                  </div>
              </div>
            </CCol>
            <CCol sm="12" class="alto mb-2">
              <gmaps-map 
                :options="mapOptions"
                @mounted="ready"
                @click="setMarker"
                @centerChanged="centerChanged"
              >
                <gmaps-marker 
                  :position="position" 
                  :options="markerOptions"
                  @move="handleMarkerPosition" 
                />
              </gmaps-map>
            </CCol>
          </CRow>
        </CustomTab>
      </CustomTabs>
      <CElementCover :opacity="1" v-show="vLoading" >
        <label class="d-inline">{{$t('label.load')}}... </label>
        <CSpinner size="sm" />
      </CElementCover>
      <template #footer>
        <CButton outline color="add" :disabled="isSubmit" @click="evaluaStatus">
          <CIcon name="checkAlt"/>&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="limpiarDatos()">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import SucursalValidations from '@/_validations/empresa/sucursalValidations';
import { isLatitude, isLongitude } from '@/_validations/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import { gmapsMap, gmapsMarker } from 'x5-gmaps';
import Fuse from "fuse.js";
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import { mapState } from 'vuex';
import MapsMixinCompany from './MapsMixinCompany';

//DATA
function data () {
  return {
    //MODELO
    CompanyId: '',
    BranchName: '',
    CountryId: '',
    StateId: '',
    MunicipalityId: '',
    ParishId: '',
    CompanyBranchId: '',
    PortId: '',
    Latitude: '',
    Longitude: '',
    Status: 1,
    UserId: '',
    pais: null,
    estado: null,
    municipio: null,
    parroquia: null,
    puerto: null,       
    tituloModal: '',
    ActualizaSucursal: false,
    Loading: false,   
    actualizar: false,   
    paises: [],    
    estados: [],    
    municipios: [],    
    parroquias: [],
    empresas: [],
    puertos: [],
    vLoading: false,
    isSubmit: false,
    position: {
      lat: 10.469697379237658, 
      lng: -68.03014330090772
    },
    mapOptions: {
      center: { lat: 10.469697379237658, lng: -68.03014330090772 },
      zoom: 12,
      mapTypeControl: true
    }
  }
}

function centerChanged(center) {
  this.position = center.toJSON();
  this.Latitude = this.position.lat;
  this.Longitude = this.position.lng;
}

function limpiarDatos() {
  this.CompanyBranchId='';
  this.CountryId= '';
  this.StateId= '';
  this.MunicipalityId= '';
  this.ParishId= '';
  this.Latitude= '';
  this.Longitude= '';
  this.CompanyId= '';
  this.BranchName= '';
  this.PortId= '';
  this.pais= null;
  this.estado= null;
  this.municipio= null;
  this.parroquia= null;
  this.puerto= null;
  this.Status=1;
  this.isSubmit = false;
  this.placeholderPos.lat = 0;
  this.placeholderPos.lng = 0;
  this.ActualizaSucursal = false;
  this.$nextTick(() => { this.$v.$reset() })
  this.$emit('cerrarModal');
}

function statusSelectColor(){
  return this.Status === 1;
}

function evaluaStatus() {
  if (this.Status===0) {   
      this.$swal
        .fire(this.alertProperties({
            text: `${this.$t('label.changeStatusQuestion')+' '} ${this.BranchName}?`,
        }))
      .then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}

function guardar() {
  try {
    this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let CompanyBranchJson = [];
    let child = '';
    this.$v.$touch();
    this.isSubmit=false;

    if (this.$v.$error){
        this.isSubmit=false;
        throw this.$t('label.errorsPleaseCheck');
    }
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.actualizar) {
      CompanyBranchJson = {
        CompanyBranchId: this.CompanyBranchId,
        CompanyId: this.CompanyId.CompanyId,
        CountryId: this.CountryId,
        StateId: this.StateId,
        MunicipalityId: this.MunicipalityId,
        ParishId: this.ParishId,
        PortId: this.PortId.PortId,
        Latitude: this.Latitude,
        Longitude: this.Longitude,
        BranchName: this.BranchName,
        Status: this.Status,
        UserId: this.user.UserId,
      };
      metodo = 'PUT';
      ruta = "Branch-update";
    } else {
      CompanyBranchJson = {
        CompanyId: this.CompanyId.CompanyId,
        CountryId: this.CountryId,
        StateId: this.StateId,
        MunicipalityId: this.MunicipalityId,
        ParishId: this.ParishId,
        PortId: this.PortId.PortId,
        Latitude: this.Latitude,
        Longitude: this.Longitude,
        BranchName: this.BranchName,
        UserId: this.user.UserId,
      };
      metodo = 'POST';
      ruta = "Branch-insert";
    }

    child = this.CompanyId.CompanyId;

    this.$http.ejecutar(metodo, ruta, CompanyBranchJson, { root: 'CompanyBranchJson' })
    .then(response => {
      res = [...response.data.data];
      this.limpiarDatos();
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
      this.isSubmit=false;
      this.$emit('child-refresh', child);
      this.ActualizaSucursal = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
      this.isSubmit=false;
    });
  } catch (err) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
    this.isSubmit=false;
  }
 
}
//----------------------------LLENADO DE LOS SELECT
function empresaOptions() {
  return this.empresas.map((empresa) => Object.assign({}, empresa, {
    label: empresa.CompanyName,
    value: empresa.CompanyId
  }));
}

function paisOptions() {
  return this.paises.map((pais) => Object.assign({}, pais, {
    label: pais.CountryName,
    value: pais.CountryId
  }));
}

function estadoOptions() {
  return this.estados.map((estado) => Object.assign({}, estado, {
    label: estado.StateName,
    value: estado.StateId
  }));
}

function municipioOptions() {
  return this.municipios.map((municipio) => Object.assign({}, municipio, {
    label: municipio.MunicipalityName,
    value: municipio.MunicipalityId
  }));
}

function parroquiaOptions() {
  return this.parroquias.map((parroquia) => Object.assign({}, parroquia, {
    label: parroquia.ParishName,
    value: parroquia.ParishId
  }));
}

function puertoOptions() {
  return this.puertos.map((ob) => Object.assign({}, ob, {
    label: ob.PortName,
    value: ob.PortId
  }));
}
//----------------------------FIN LLENADO DE LOS SELECT
//----------------------------CONSUMO APIS UBICACION
function getPaises() {  
  this.Loading = true;
  this.$http.get("Country-list", { Filter: "ACTIVO" })
  .then((response) => {
    this.paises = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.Loading = false;
  });
}

function getEstados(pais) {
  this.vLoading = true;
  this.$http.get("State-list", { CountryId: pais })
  .then((response) => {
    this.estados = response.data.data;
    this.$refs.vEstado.$el.focus();
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.vLoading = false;
  });
}

function getMunicipios(estado) {
  this.vLoading = true;
  this.$http.get("Municipality-list", { StateId: estado })
  .then((response) => {
    this.municipios = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.vLoading = false;
  });
}

function getParroquias(municipio) {
  this.vLoading = true;
  this.$http.get("Parish-list", { MunicipalityId: municipio })
  .then((response) => {
    this.parroquias = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.vLoading = false;
  });
}

function getPuertos(estado) {
  this.$http.get("Port-by-state", { StateId: estado })
  .then((response) => {
    this.puertos = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}
//----------------------------FIN CONSUMO APIS UBICACION
//----------------------------FUSES
function fuseSearchPais(options, search) {
  const fuse = new Fuse(options, {
    keys: ["CodIsoAlpha2", "CodIsoAlpha3", "CountryName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchEstado(options, search) {
  const fuse = new Fuse(options, {
    keys: ["StateName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchMunicipios(options, search) {
  const fuse = new Fuse(options, {
    keys: ["MunicipalityName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchParroquias(options, search) {
  const fuse = new Fuse(options, {
    keys: ["ParishName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchEmpresa(options, search) {
  const fuse = new Fuse(options, {
    keys: ["CompanyName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function fuseSearchPuerto(options, search) {
  const fuse = new Fuse(options, {
    keys: ["PortName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}
//----------------------------FIN FUSES
//COMPUTED
function isPaisValid() {
  return this.CountryId == '' ? 'no-valido' : 'valido';
}

function isEstadoValid() {
  return this.StateId == '' ? 'no-valido' : 'valido';
}

function isMunicipioValid() {
  return this.MunicipalityId == '' ? 'no-valido' : 'valido';
}

function isParroquiaValid() {
  return this.ParishId == '' ? 'no-valido' : 'valido';
}

function isEstadosEmpty(){
  return this.estados.length == 0;
}

function isMunicipiosEmpty(){
  return this.municipios.length == 0;
}

function isParroquiasEmpty(){
  return this.parroquias.length == 0;
}

function isEmpresaValid() {
  return (this.CompanyId == null || this.CompanyId == "") ? 'no-valido' : 'valido';
}

function isPuertoEmpty(){
  return this.puertos.length == 0;
}

function isPuertoValid(){
  return (this.PortId == null || this.PortId == "") ? 'no-valido' : 'valido';
}

function selectOptions(){

  return [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ]
}
export default {
  name: 'sucursal-modal',
  mixins: [General,ModalMixin,MapsMixinCompany],
  components:{ 
    gmapsMap, 
    gmapsMarker,
    CustomTabs,
    CustomTab
  },
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: SucursalValidations,
  watch: {
    modal: function (val) {
      if (val) {
        this.ActualizaSucursal = true;
        let listado = [];
        if (this.modal.Item==true) {
          this.actualizar = false;
          this.tituloModal = this.$t('label.nueva')+this.$t('label.branch');
        } else {
          this.actualizar     = true;
          this.tituloModal    = this.$t('label.edit')+this.$t('label.branch')+": "+this.modal.Item.Sucursal;
          this.CompanyBranchId= this.modal.Item.CompanyBranchId;
          this.BranchName     = this.modal.Item.Sucursal;
          this.Latitude       = this.modal.Item.Latitude;
          this.Longitude      = this.modal.Item.Longitude;
          this.CountryId      = this.modal.Item.CountryId;
          this.StateId        = this.modal.Item.StateId;
          this.MunicipalityId = this.modal.Item.MunicipalityId;
          this.ParishId       = this.modal.Item.ParishId;
          this.Status         = this.modal.Item.FgActBranch ? 1 : 0;
          this.selectOptions[this.Status];

          this.CompanyId = {
            CompanyName: (this.modal.Item.CompanyRif +" - "+ this.modal.Item.CompanyName),
            CompanyId: this.modal.Item.CompanyId
          }

          this.pais = {
            CountryId: this.modal.Item.CountryId,
            CountryName: this.modal.Item.CountryName
          }

          this.estado = {
            StateId: this.modal.Item.StateId,
            StateName: this.modal.Item.StateName
          }

          this.municipio = {
            MunicipalityId: this.modal.Item.MunicipalityId,
            MunicipalityName: this.modal.Item.MunicipalityName
          }

          this.parroquia = {
            ParishId: this.modal.Item.ParishId,
            ParishName: this.modal.Item.ParishName
          }

          this.PortId = {
            PortName: this.modal.Item.PortName,
            PortId: this.modal.Item.PortId
          }
        this.$v.$touch();
        }

        listado = this.modal.Empresas.map(ob => Object.assign({}, listado, {
          CompanyId: ob.CompanyId,
          CompanyName: (ob.CompanyRif +" - "+ ob.CompanyName)
        }));
        this.empresas = listado;
        this.getPaises();
      }
    },
    latitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.Latitude) && isLongitude(this.Longitude)){
        this.updatePosition(Number.parseFloat(this.Latitude), Number.parseFloat(this.Longitude));
      }
    },
    longitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.Latitude) && isLongitude(this.Longitude)){
        this.updatePosition(Number.parseFloat(this.Latitude), Number.parseFloat(this.Longitude));
      }
    },
    pais: function(newPais) {
      if(newPais){
        if(this.CountryId !== newPais.CountryId) {
          this.estado = null;
          this.municipio = null;
          this.parroquia = null;
          this.municipios = [];
          this.municipios.length = 0;
          this.parroquias = [];
          this.parroquias.length = 0;
          this.StateId = '';
          this.MunicipalityId = '';
          this.ParishId = '';
          this.CountryId = newPais.CountryId
        }
        this.getEstados(newPais.CountryId);
        if(newPais.Latitude && newPais.Longitude){
          this.updatePosition(Number.parseFloat(newPais.Latitude), Number.parseFloat(newPais.Longitude));
          this.handleMarkerPosition({
            lat: Number.parseFloat(newPais.Latitude),
            lng: Number.parseFloat(newPais.Longitude)
          });
        }
      }else{
        this.estados = [];
        this.estados.length = 0;
      }
    },
    estado: function(newEstado) {
      if(newEstado){
        if(this.StateId !== newEstado.StateId) {
          this.municipio = null;
          this.parroquia = null;
          this.parroquias = [];
          this.parroquias.length = 0;
          this.MunicipalityId = '';
          this.ParishId = '';
          this.StateId = newEstado.StateId
        }
        this.getMunicipios(newEstado.StateId);
        this.getPuertos(newEstado.StateId);
      }else{
        this.municipios = [];
        this.municipios.length = 0;
      }
    },
    municipio: function(newMunicipio) {
      if(newMunicipio){
        if(this.MunicipalityId !== newMunicipio.MunicipalityId) {
          this.parroquia = null;
          this.ParishId = '';
          this.MunicipalityId = newMunicipio.MunicipalityId;
        }
        this.getParroquias(newMunicipio.MunicipalityId);
      }else{
        this.parroquias = [];
        this.parroquias.length = 0;
      }
    },
    parroquia: function(newParroquia) {
      if (newParroquia) this.ParishId = newParroquia.ParishId
    }
  },
  methods: {
    centerChanged,
    statusSelectColor,
    getPaises,
    fuseSearchPais,
    getEstados,
    fuseSearchEstado,
    getMunicipios,
    fuseSearchMunicipios,
    getParroquias,
    fuseSearchParroquias,
    fuseSearchEmpresa,
    fuseSearchPuerto,
    getPuertos,
    limpiarDatos,
    evaluaStatus,
    guardar
  },
  computed: {
    isPaisValid,
    isEstadoValid,
    isEmpresaValid,
    paisOptions,
    isEstadosEmpty,
    estadoOptions,
    selectOptions,      
    isMunicipiosEmpty,
    municipioOptions,
    isMunicipioValid,
    isParroquiasEmpty,
    parroquiaOptions,
    isParroquiaValid,
    isPuertoValid,
    isPuertoEmpty,
    empresaOptions,
    puertoOptions,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style scoped>
.alto {
  min-height: 200px !important;
  max-height: 200px !important;
}
</style>

<style lang="scss">

.text-table .custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>

